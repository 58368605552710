'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

function appendScriptFromString(jsString: string)
{
	const newScriptElement = document.createElement('script');
	newScriptElement.innerHTML = `window.__custom_apnt_func__=()=>{${jsString}}`;
	document.body.appendChild(newScriptElement);
}

export default function ApntJS({ scriptCode }: { scriptCode: string })
{
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() =>
	{
		appendScriptFromString(scriptCode);
	})

	useEffect(() =>
	{

		let cb = () =>
		{
			if (window && (window as any).__custom_apnt_func__)
			{
				(window as any).__custom_apnt_func__();
			}
		}

		let links = document.querySelectorAll("a");
		for (let i = 0, len = links.length; i < len; i++)
		{
			let href = links[i].getAttribute("href");
			if (
				href == "#make-appointment" ||
				links[i].classList.contains("av-appointment-link")
			)
			{
				const el = links[i];
				if (el && el.removeEventListener)
				{
					el.removeEventListener("click", cb, false);
					el.addEventListener("click", cb, false);
				}
			}
		}
	}, [pathname, searchParams])
	return <></>;
}

